import React, { useState } from "react";
import styled from "styled-components";
import NewNavbarSocials from "./NewNavbarSocials";
import { entityPresentationSiteUrl } from "../../../../config.dev";
import { routeNames } from "../../../../routes/routes";
import { Link } from "react-router-dom";
import NewNavbarLaunchButton from "./NewNavbarLaunchButton";
import NewNavbarMenu from "./NewNavbarMenu";
import Logo from "../../../../assets/img/logo.png";
import BoltGlow from "../../../../assets/images/boltGlow.svg";

const StyledContainer = styled("div")`
    p {
        margin-bottom: 0;
        font-size: 0.7em;
        line-height: 1.2em;
        color: white;
    }

    .nav-topbar {
        z-index: 10;
        width: 100%;
        height: 5em;
        background-color: #141719;
        color: #141719;
        text-align: center;
        flex-wrap: nowrap;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-columns: 1fr;
        justify-content: space-between;
        align-items: center;
        padding: 0 1vw;
        display: grid;
        position: relative;
        overflow: hidden;
    }

    .banner-background {
        background: black;
    }

    .nav-brand-name {
        color: white;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-family: var(--entity-header-font-family), sans-serif;
        font-size: 1.2em;
        font-weight: 500;
        line-height: 1em;
    }

    .nav-brand-image {
        z-index: 10;
        width: 8em;
        height: 2em;
        object-fit: contain;
        object-position: 0% 50%;
        flex: none;
        display: block;
        position: relative;
    }

    .nav-topbar-contact {
        grid-column-gap: 2.5em;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        display: flex;
    }

    .nav-topbar-contact-links {
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        display: flex;
    }

    .nav-topbar-contact-link {
        color: #09998c;
        letter-spacing: 0.05em;
        text-indent: 0.05em;
        text-transform: uppercase;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        align-items: flex-start;
        padding: 0.8em;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1.2em;
        display: flex;
    }

    .nav-topbar-contact-link:hover {
        color: #34e7d7;
    }

    .nav-topbar-contact-links {
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        display: flex;
    }

    .nav {
        z-index: 995;
        width: 100%;
        background-color: rgba(255, 255, 255, 0);
        flex-direction: column;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-auto-columns: 1fr;
        justify-content: space-between;
        align-items: stretch;
        font-weight: 400;
        display: flex;
        position: absolute;
        top: 0%;
        bottom: auto;
        left: 0%;
        right: 0%;
    }

    .nav-main {
        z-index: 200;
        width: 100%;
        height: 7em;
        min-height: 7em;
        border-top: 1px solid #077f75;
        border-right: 1px solid #077f75;
        border-bottom: 1px solid #077f75;
        color: #83ff8c;
        background-color: rgba(1, 25, 23, 0.3);
        flex-wrap: nowrap;
        grid-template-rows: auto;
        grid-template-columns: 1fr 0.5fr 1fr;
        grid-auto-columns: 1fr;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding-left: 12vw;
        font-family: var(--entity-header-font-family), sans-serif;
        font-size: 0.7vw;
        transition: border-color 0.2s, background-color 0.2s;
        display: flex;
        position: relative;
    }

    .nav-main-link-label {
        z-index: 10;
        border-style: solid;
        border-width: 0 0 1px 1px;
        border-color: #077f75;
        background-color: rgba(114, 250, 238, 0.1);
        color: #0bccbb;
        padding: 0.3em;
        font-size: 0.7em;
        line-height: 1em;
        position: absolute;
        top: 0%;
        bottom: auto;
        left: auto;
        right: 0%;
    }

    .nav-topbar-text {
        text-transform: uppercase;
        align-self: center;
        font-family: var(--entity-header-font-family), sans-serif;
        font-size: 0.8em;
        line-height: 1em;
    }

    .nav-brand {
        z-index: 10;
        grid-column-gap: 1.25em;
        color: #72faee;
        background-color: rgba(0, 0, 0, 0);
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        padding: 1em 2vw;
        transition: color 0.2s;
        display: flex;
    }

    .nav-brand:hover {
        color: #ffffff;
    }

    .nav-social-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .nav-social-link {
        min-width: 5em;
        border-right: 1px solid rgba(255, 255, 255, 0);
        border-left: 1px solid rgba(255, 255, 255, 0);
        opacity: 0.6;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 1em;
        transition: min-width 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.2s, border-color 0.2s, background-color 0.2s;
        display: flex;
    }

    .nav-social-link:hover {
        min-width: 6em;
        border-right-color: #077f75;
        border-left-color: #077f75;
        background-color: rgba(114, 250, 238, 0.1);
        opacity: 1;
    }

    .button-for-open-menu {
        z-index: 900;
        height: 7em;
        min-width: 12vw;
        grid-column-gap: 2em;
        border: 1px solid #077f75;
        background-color: #011917;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 2vw;
        padding-right: 2vw;
        font-weight: 500;
        transition: border-color 0.2s, background-color 0.2s;
        display: flex;
        position: fixed;
        left: 0;
        overflow: hidden;
    }

    .button-for-open-menu:hover {
        border: 1px solid #077f75;
        background-color: #03332f;
    }

    .menu-burger-icon {
        z-index: 10;
        width: 2em;
        height: 1.1em;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .menu-burger-line {
        width: 100%;
        height: 1px;
        background-color: #34e7d7;
    }

    .menu-button-text {
        z-index: 10;
        color: #72faee;
        font-size: 1.2em;
        line-height: 1em;
        position: relative;
    }

    .nav-main-links-wrapper {
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-self: stretch;
        align-items: stretch;
        display: flex;
        position: static;
    }

    .nav-main-link {
        z-index: 10;
        width: 100%;
        grid-column-gap: 1em;
        border-style: solid;
        border-width: 1px 1px 1px 0;
        border-color: rgba(255, 255, 255, 0) #077f75 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
        color: #83ff8c;
        text-align: center;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 0;
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        padding: 0.8em 0.5em;
        font-size: 1.1em;
        font-weight: 500;
        line-height: 0.8em;
        transition: width 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.2s, color 0.2s;
        display: flex;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }

    .nav-main-link:hover {
        width: 115%;
        background-color: rgba(114, 250, 238, 0.1);
        color: #b7f1ec;
    }

    .nav-main-link.w--current {
        border-bottom-color: #95f0e8;
        background-color: rgba(114, 250, 238, 0.05);
    }

    .nav-main-link.w--current:hover {
        background-color: rgba(114, 250, 238, 0.1);
    }

    .nav-main-links-wrapper {
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-self: stretch;
        align-items: stretch;
        display: flex;
        position: static;
    }

    .nav-main-link-label {
        z-index: 10;
        border-style: solid;
        border-width: 0 0 1px 1px;
        border-color: #077f75;
        background-color: rgba(114, 250, 238, 0.1);
        color: #0bccbb;
        padding: 0.3em;
        font-size: 0.7em;
        line-height: 1em;
        position: absolute;
        top: 0%;
        bottom: auto;
        left: auto;
        right: 0%;
    }

    .nav-secondary-links-wrapper {
        z-index: 100;
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        justify-content: flex-end;
        align-self: stretch;
        align-items: center;
        padding-left: 2vw;
        padding-right: 2vw;
        display: flex;
        position: relative;
    }

    .nav-contact-button {
        z-index: 10;
        min-height: 3.2em;
        grid-column-gap: 1em;
        border: 1px solid #09998c;
        border-radius: 6px;
        background-color: rgba(114, 250, 238, 0.05);
        color: #83ff8c;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        align-items: center;
        margin-left: 1.5em;
        padding: 0.8em;
        font-weight: 600;
        transition: background-color 0.2s, border-color 0.2s, color 0.2s;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    @media screen and (min-width: 1920px) {
        .nav-main-link {
            font-family: var(--entity-header-font-family), sans-serif;
            font-size: 1.4em;
        }

        .nav-brand-name {
            font-family: var(--entity-header-font-family), sans-serif;
        }
    }

    @media screen and (max-width: 991px) {
        .nav-topbar {
            height: 5em;
            grid-template-columns: 1fr;
            padding-left: 0;
            padding-right: 0;
        }

        .nav-secondary-links-wrapper {
            flex: 1;
        }

        .nav-main {
            width: 100%;
            height: 5em;
            min-height: 5em;
            padding-left: 15vw;
            font-size: 11px;
        }

        .nav-main-links-wrapper {
            align-self: auto;
            display: none;
        }

        .nav-main-links-wrapper {
            align-self: auto;
            display: none;
        }

        .nav-topbar-contact {
            display: none;
        }

        .nav-brand-name {
            font-size: 1.3em;
        }

        .nav-brand-image {
            width: 2em;
            height: 2em;
        }

        .nav-brand {
            padding: 1em 4vw;
            transition: background-color 0.2s;
        }

        .nav-brand:hover {
            width: auto;
            letter-spacing: -0.025em;
            text-indent: -0.025em;
        }

        .nav-brand-image {
            width: 2em;
            height: 2em;
        }

        .menu-button-text {
            font-size: 1em;
        }

        .nav-main-links-wrapper {
            align-self: auto;
            display: none;
        }

        .button-for-open-menu {
            height: 5em;
            grid-column-gap: 2vw;
            grid-row-gap: 2vw;
            flex: none;
            font-size: 11px;
            transition: none;
        }

        .nav-secondary-links-wrapper {
            flex: 1;
            font-size: 11px;
        }
    }

    @media screen and (max-width: 767px) {
        .nav-topbar {
            height: 4em;
        }

        .nav-main {
            height: 2em;
            min-height: 4em;
            padding-left: 17vw;
        }

        .nav-brand-name {
            font-size: 1.3em;
        }

        .nav-brand-image {
            height: 1.6em;
        }

        .nav-brand-name {
            font-size: 1.1em;
        }

        .nav-brand {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        .nav-brand-image {
            height: 1.6em;
        }

        .menu-burger-icon {
            font-size: 0.9em;
        }

        .menu-burger-icon {
            font-size: 0.9em;
        }

        .menu-button-text {
            font-size: 0.9em;
        }

        .button-for-open-menu {
            height: 4em;
        }
    }

    @media screen and (max-width: 479px) {
        .nav-topbar {
            height: 3.5em;
        }

        .nav-brand-name {
            font-size: 1em;
        }

        .nav-brand {
            grid-column-gap: 0.45em;
        }

        .menu-burger-icon {
            font-size: 0.8em;
        }

        .menu-burger-icon {
            font-size: 0.8em;
        }

        .nav-secondary-links-wrapper {
            z-index: 901;
            grid-column-gap: 2vw;
            grid-row-gap: 2vw;
            -webkit-backdrop-filter: none;
            backdrop-filter: none;
        }

        .button-for-open-menu {
            grid-column-gap: 4vw;
            grid-row-gap: 4vw;
        }

        .nav-main {
            padding-left: 27vw;
        }
    }
`;

const TopBannerContainer = styled.div`
    background: var(--table-col-bg-color);
    padding: 0.5rem;
    font-weight: bold;
    color: var(--white);
    position: relative;
    width: 100%;
    font-size: 0.6rem;
    background: #094943;

    @media (min-width: 415px) {
        font-size: 0.8rem;
    }

    @media (min-width: 500px) {
        font-size: 1rem;
    }

    @media (min-width: 768px) {
        margin-bottom: 0.8rem;
    }

    @media (min-width: 1202px) {
        margin-bottom: 0;
    }
`;

const NewNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const onOpenMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <StyledContainer>
                <div
                    data-animation="default"
                    data-collapse="none"
                    data-duration="0"
                    data-easing="ease"
                    data-easing2="ease"
                    role="banner"
                    className="nav w-nav"
                >
                    <div className="nav-topbar">
                        <div className="nav-topbar-contact">
                            <img src={Logo} width="14" height="14" alt="" className="nav-brand-image" />
                            <p className="nav-topbar-text">Get in touch</p>
                            <div className="nav-topbar-contact-links">
                                <a
                                    href="mailto:info@entity.global?subject=Contact%20from%20website"
                                    className="nav-topbar-contact-link"
                                >
                                    info@entity.global
                                </a>
                            </div>
                        </div>
                        <a href="https://entity.global" className="nav-brand w-nav-brand">
                            <p className="nav-brand-name">
                                <strong>Entity</strong>
                            </p>
                        </a>
                        <NewNavbarSocials />
                    </div>
                    <TopBannerContainer className="d-flex justify-content-center align-items-center z-1 mt-2">
                        <div className="flex items-center justify-center gap-2 background">
                            <div className="flex items-center justify-center text-sm px-3 text-white my-3">
                                <img className="h-6 w-6 scale-x-[-1] me-1" src={BoltGlow} alt="Entity Surge Bolt" />
                                <span className={"text-center"}>
                                    Turn up the voltage and experience the power of{" "}
                                    <a
                                        href="https://surge.entity.global"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-success"
                                    >
                                        Entity Surge
                                    </a>{" "}
                                    NOW!
                                </span>
                                <img className="h-6 w-6 ms-1" src={BoltGlow} alt="Entity Surge Bolt" />
                            </div>
                        </div>
                    </TopBannerContainer>
                    <div className="nav-main">
                        <div onClick={onOpenMenu} className="button-for-open-menu">
                            <div className="menu-burger-icon">
                                <div className="menu-burger-line is-1st"></div>
                                <div className="menu-burger-line is-2nd"></div>
                                <div className="menu-burger-line is-3rd"></div>
                            </div>
                            <div className="menu-button-text">Menu</div>
                        </div>
                        <nav role="navigation" className="nav-main-links-wrapper w-nav-menu">
                            <Link to={routeNames.launchpad} className="nav-main-link">
                                Launchpad
                            </Link>
                            <a href={`${entityPresentationSiteUrl}#products`} className="nav-main-link w-inline-block">
                                <div>Cross-Chain Swap</div>
                                <div className="nav-main-link-label">coming soon</div>
                            </a>
                            <a href={`${entityPresentationSiteUrl}#products`} className="nav-main-link w-inline-block">
                                <div>Bridge Aggregator</div>
                                <div className="nav-main-link-label">coming soon</div>
                            </a>
                            <a href={`${entityPresentationSiteUrl}#team`} className="nav-main-link">
                                Team
                            </a>
                            <a href="https://entity.gitbook.io/entity" className="nav-main-link" target="_blank">
                                Knowledge Base
                            </a>
                            <a
                                href="https://app.galxe.com/quest/NoCq8h2VXsBBaKZxFhQz8J/GCawhthesx"
                                target="_blank"
                                className="nav-main-link"
                            >
                                Campaigns
                            </a>
                        </nav>
                        <div className="nav-secondary-links-wrapper">
                            <NewNavbarLaunchButton />
                        </div>
                    </div>
                </div>
            </StyledContainer>
            <NewNavbarMenu showMenu={menuOpen} onMenuClose={() => setMenuOpen(false)} />
        </>
    );
};

export default NewNavbar;
